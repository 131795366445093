<template>
    <DeviceModify :device-group-id="deviceGroupId"/>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import AdminExtension from '../AdminExtension'
import DeviceModify from './DeviceModify'

export default AdminExtension.extend({
    name: 'DeviceAdd',
    props: {
    	deviceGroupId: {
    		require: false,
    		type: String,
    	}
    },
    components: {
        DeviceModify,
    },
})
</script>

